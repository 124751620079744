@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap");
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "IBM Plex Sans", sans-serif;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f5f5f5;
}

.sc-gsTDqH>div {
  font-family: "Poppins", sans-serif;
}

.icon__card {
  right: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

body {
  position: relative;
}

/* BasicDateCalendar.css */

/* Add this to your CSS */
.active-day {
  background-color: #b3d4fc;
  /* Change this to the desired background color for the active day */
  color: #000000;
  /* Change this to the desired text color for the active day */
}

/* Add this for the tooltip */
.tooltip {
  position: absolute;
  background-color: #ffffff;
  /* Change this to the desired background color for the tooltip */
  border: 1px solid #cccccc;
  /* Change this to the desired border color for the tooltip */
  padding: 8px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Change this for the desired box shadow */
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.home {
  /* overflow-y: scroll !important;
  overflow: scroll; */
}




.GridCourses {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  justify-items: center;
}

.GridCourses .test:nth-child(5) .details {
  left: -115%;
}

.GridCourses .test:nth-child(10) .details {
  left: -115%;
}

.GridCourses .test:nth-child(5) .details .left {
  visibility: hidden;
}

.GridCourses .test:nth-child(10) .details .left {
  visibility: hidden;
}

.GridCourses .test:nth-child(5) .details .right {
  display: block;
}

.GridCourses .test:nth-child(10) .details .right {
  display: block;
}

.overflow {
  width: 100%;
  position: fixed;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 20;
  top: 0;
}

.overview {
  background-color: rgba(0, 0, 0, 0.137);
}

.add__course {
  z-index: 30;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  position: fixed;
}

.delete {
  position: fixed;
  z-index: 50;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Style the custom checkbox */
.custom-checkbox .checkmark {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 4px;
}

/* Style the custom checkbox when it's checked */
.custom-checkbox input:checked+.checkmark {
  background-color: #fff;
  border: 2px solid #000;
}

/* Create a checkmark symbol using pseudo-elements */
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: 2px solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Show the checkmark when the checkbox is checked */
.custom-checkbox input:checked+.checkmark:after {
  display: block;
}

/* Style the label text */
.custom-checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
}

/* When the checkbox is checked, change the text color */
.custom-checkbox input:checked+.checkmark+label {
  color: #2196f3;
}

.add::-webkit-scrollbar {
  display: none;
}

.add {
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.date__range input {
  background-color: white;
}

/* .test > div > div {
  background-color: white;
  color: #000;
  border: 1px solid #0000001c;
}
.test > div > div > div {
  background-color: white;
  color: #000;
}
.test > div > div > button {
  background-color: white;
  color: #000;
} */
.card__lessons {
  transition: all 3s;
}

.card__lessons:hover .icon__delete {
  display: block;
  transition: all 3s;
}

.bg__hover {
  transition: all 3s;
}

.card__lessons:hover .bg__hover {
  display: block;
}

.icon_not:hover .noti {
  display: block;
  width: 385px;
  transition: all 0.3s;
}

.noti {
  transition: all 0.3s;
  display: none;
  width: 0;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #2d78db;
  position: absolute;
  top: -8px;
  right: 8px;
  border-radius: 50px;
}

.css-1rv8zyt-MuiStack-root {
  overflow: hidden !important;
}

.MuiCircularProgress-root {
  width: 20px;
  height: 20px;
}

.custom-checkbox__login input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Style the custom checkbox */
.custom-checkbox__login .checkmark__login {
  position: relative;
  display: inline-block;
  /* width: 20px;
  height: 20px; */
  /* background-color: #0455ba; */
  /* border: 3px solid #fff; */
  border-radius: 4px;
  cursor: pointer;
}

/* Style the custom checkbox when it's checked */
.custom-checkbox__login input:checked+.checkmark__login {
  background-color: #000;
  border: 1px solid #000;
}

/* Create a checkmark symbol using pseudo-elements */
.custom-checkbox__login .checkmark__login:after {
  content: "";
  position: absolute;
  display: none;
  cursor: pointer;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid #38aee6;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Show the checkmark when the checkbox is checked */
.custom-checkbox__login input:checked+.checkmark__login:after {
  display: block;
}

/* Style the label text */
.custom-checkbox__login label {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
}

/* When the checkbox is checked, change the text color */
.custom-checkbox__login input:checked+.checkmark+label {
  color: #2196f3;
}

.reset__password {
  position: absolute;
  z-index: 50;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.custom-checkbox__singup input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Style the custom checkbox */
.custom-checkbox__singup .checkmark {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 4px;
}

/* Style the custom checkbox when it's checked */
.custom-checkbox__singup input:checked+.checkmark {
  background-color: #fff;
  border: 2px solid #000;
}

/* Create a checkmark symbol using pseudo-elements */
.custom-checkbox__singup .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: 2px solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Show the checkmark when the checkbox is checked */
.custom-checkbox__singup input:checked+.checkmark:after {
  display: block;
}

/* Style the label text */
.custom-checkbox__singup label {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
}

/* When the checkbox is checked, change the text color */
.custom-checkbox__singup input:checked+.checkmark+label {
  color: #2196f3;
}

.react-tel-input .form-control {
  width: 85% !important;
  height: 50px !important;
  font-size: 16px !important;
  border: 1px solid #9ca3af !important;
  right: -15% !important;
  padding-left: 15px !important;
}

.react-tel-input .flag-dropdown {
  width: 13%;
  border-radius: 6px !important;
  padding: 7px !important;
  border: 1px solid #9ca3af !important;
  background-color: white !important;
}

.about__user .react-tel-input .form-control {
  width: 85% !important;
  height: 50px !important;
  font-size: 16px !important;
  border: 1px solid #9ca3af !important;
  right: -15% !important;
  padding-left: 15px !important;
}

.about__user .react-tel-input .flag-dropdown {
  width: 13%;
  border-radius: 6px !important;
  padding: 7px !important;
  border: 1px solid #9ca3af !important;
}

.about__user__edit .react-tel-input .form-control {
  width: 85% !important;
  height: 50px !important;
  font-size: 16px !important;
  border: 1px solid #9ca3af !important;
  right: -15% !important;
  padding-left: 15px !important;
  background-color: #f5f5f5 !important;
  color: #6b7280;
}

.about__user__edit .react-tel-input .flag-dropdown {
  width: 13%;
  border-radius: 6px !important;
  padding: 7px !important;
  border: 1px solid #9ca3af !important;
  background-color: #f5f5f5 !important;
}

.about__user {}

.straming {
  position: absolute;
  z-index: 30;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

/* .gHOYdA {
  color: white;
} */
.sc-ezrdqu {
  color: white;
}